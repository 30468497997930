import $axios from '@/plugins/axios'
const model = 'contactUs'
export default {
  state: {
    contacts: []
  },
  getters: {
    contacts: state => state.contacts
  },
  actions: {
    async getContacts ({ commit }, filterData = { take: 10, skip: 0, full_name: '', phone: '', email: '' }) {
      const response = await $axios.get(`/${model}?include=answeredBy&take=${filterData.take}&skip=${filterData.skip}&filter[full_name]=${filterData.full_name}&filter[phone]=${filterData.phone}&filter[email]=${filterData.email}`)
      commit('SET_CONTACTS', response.data)
    },

    async sendAnswer ({ commit }, payload) {
      await $axios.put(`/answer-customer-message/contactUs/${payload.id}`, payload)
      return commit
      // commit('UPDATE_CONTACTS', response.data.data)
    },

    async removeContacts ({ commit }, payload) {
      const response = await $axios.delete(`/${model}/${payload.id}`)
      commit('REMOVE_CONTACTS', response.data.data.id)
    }
  },
  mutations: {
    SET_CONTACTS (state, data) {
      state.contacts = data
    },
    UPDATE_CONTACTS (state, data) {
      state.contacts.data = state.contacts.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_CONTACTS (state, id) {
      state.contacts.data = state.contacts.data.filter(x => x.id !== id)
    }

  }
}
