import $axios from '@/plugins/axios'
export default {
  state: {
  },
  getters: {
  },
  actions: {
    async sendNotificationToClient ({ commit }, payload) {
      await $axios.post('/send-notify-to-client', payload)
    },
    async sendNotificationToUser ({ commit }, payload) {
      await $axios.post('/send-notify-to-user', payload)
    }
  }
}
