<template>
  <router-view/>
</template>
<script>
import { core } from './config/pluginInit'

export default {
  name: 'App',
  components: {
  },
  mounted () {
    core.mainIndex()
    if (this.$i18n.locale === 'kr') {
      document.documentElement.classList.add('kr-lang')
    }
  }
}
</script>
<style lang="scss">
  @import "assets/scss/style.scss";
</style>
