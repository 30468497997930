import $axios from '@/plugins/axios'
const model = 'commonQuestion'
export default {
  state: {
    question: []
  },
  getters: {
    question: state => state.question
  },
  actions: {
    async getQuestion ({ commit }, filterData = { page: 1 }) {
      const response = await $axios.get(`/${model}?take=10&page=${filterData.page}`)
      commit('SET_QUESTION', response.data)
    },
    async addQuestion ({ commit }, payload) {
      const response = await $axios.post(`/${model}`, payload)
      commit('SORT_QUESTION', response.data.data)
    },
    async updateQuestion ({ commit }, payload) {
      const response = await $axios.put(`/${model}/${payload.id}`, payload)
      commit('UPDATE_QUESTION', response.data.data)
    },
    async removeQuestion ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_QUESTION', response.data.data.id)
    }
  },
  mutations: {
    SET_QUESTION (state, data) {
      state.question = data
    },
    SORT_QUESTION (state, data) {
      state.question.data.push(data)
    },
    UPDATE_QUESTION (state, data) {
      state.question.data = state.question.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_QUESTION (state, id) {
      state.question.data = state.question.data.filter(x => x.id !== id)
    }
  }
}
