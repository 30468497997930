import $axios from '@/plugins/axios'
const model = 'user'
export default {
  state: {
    users: [],
    permissionsUser: []
  },
  getters: {
    users: state => state.users,
    permissionsUser: state => state.permissionsUser
  },
  actions: {
    async getUsers ({ commit }, filterData = { page: 1, name: '' }) {
      const response = await $axios.get(`/${model}?include=roles&take=10&page=${filterData.page}`)
      commit('SET_USERS', response.data)
    },
    async getHotelUsers ({ commit }, filterData = { page: 1, name: '' }) {
      const response = await $axios.get(`/hotel-users?include=hotel&take=10&page=${filterData.page}`)
      commit('SET_USERS', response.data)
    },
    async addUsers ({ commit }, payload) {
      const response = await $axios.post(`/${model}`, payload)
      commit('SORT_USERS', response.data.data)
    },
    async updateUsers ({ commit }, payload) {
      const response = await $axios.post(`/${model}/${payload.id}?_method=PUT`, payload.payload)
      commit('UPDATE_USERS', response.data.data)
    },
    async removeUsers ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_USERS', response.data.data.id)
    },
    async toggleIsActive ({ commit }, { id, isActive }) {
      return await $axios.post(`/${model}/${id}?_method=PUT`, { is_active: isActive })
    },
    async assignPermissionToUser ({ commit }, id) {
      const response = await $axios.delete(`/give-permissions-to/user/${id}`)
      commit('REMOVE_USERS', response.data.data.id)
    },
    async userPermissions ({ commit }) {
      const id = JSON.parse(localStorage.getItem('booking-admin-info')).userAuth.id
      const response = await $axios.get(`/premissions/user/${id}`)
      commit('USER_PERMISSIONS', response.data)
    }
  },
  mutations: {
    SET_USERS (state, data) {
      state.users = data
    },
    SORT_USERS (state, data) {
      state.users.data.push(data)
    },
    UPDATE_USERS (state, data) {
      state.users.data = state.users.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_USERS (state, id) {
      state.users.data = state.users.data.filter(x => x.id !== id)
    },
    USER_PERMISSIONS (state, data) {
      state.permissionsUser = data
      console.log(this.permissionsUser)
    }

  }
}
