import $axios from '@/plugins/axios'
import i18n from '@/i18n'
const model = 'feature'
export default {
  state: {
    features: []
  },
  getters: {
    features: state => state.features
  },
  actions: {
    async getFeatures ({ commit }, filterData = {
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: ''
    }) {
      const response = await $axios.get(`/${model}?include=features,createdBy,updatedBy&take=10&page=${filterData.page}&filter[${i18n.locale}_name]=${filterData[`${i18n.locale}_name`]}`)
      commit('SET_FEATURES', response.data)
    },
    async addFeatures ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
      // commit('SORT_FEATURES', response.data.data)
    },

    async updateFeatures ({ commit }, payload) {
      await $axios.put(`/${model}/${payload.id}`, payload)
      // commit('UPDATE_FEATURES', response.data.data)
    },

    async removeFeatures ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_FEATURES', response.data.data.id)
    }
  },
  mutations: {
    SET_FEATURES (state, data) {
      state.features = data
    },
    SORT_FEATURES (state, data) {
      state.features.data.push(data)
    },
    UPDATE_FEATURES (state, data) {
      state.features.data = state.features.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_FEATURES (state, id) {
      state.features.data = state.features.data.filter(x => x.id !== id)
    }

  }
}
