import $axios from '@/plugins/axios'

const model = 'reservations'
// /api/reservations?include=client,reserveRooms&take=20
export default {
  state: {
    reservations: [],
    reservation: {}
  },
  getters: {
    reservstionsList: state => state.reservations,
    reservation: state => state.reservation
  },
  actions: {
    async getReservationList ({ commit }, filterData = {
      take: 10,
      page: 0,
      hotel_id: '',
      reservation_no: ''
    }) {
      const response = await $axios.get(`/${model}?include=client,reserveRooms,hotel&take=${filterData.take}&page=${filterData.page}&filter[reservation_no]=${filterData.reservation_no || ''}&filter[hotel_id]=${filterData.hotel_id || ''}`)
      commit('SET_RESERVATIONS', response.data)
    },
    async addReservations ({ commit }, payload) {
      return await $axios.post(`/${model}`, payload)
    },

    async downloadReservation ({ commit }, payload) {
      const response = await $axios.get(`/download/reserve/${payload.id}`, {
        responseType: 'blob'
      })
      const blob = new Blob([response.data], {
        type: 'application/pdf'
      })
      // Create a temporary anchor element
      const downloadLink = document.createElement('a')
      downloadLink.href = window.URL.createObjectURL(blob)

      // Set the download attribute with the desired file name
      downloadLink.setAttribute('download', payload.reservation_no + '.pdf')

      // Append the anchor element to the document body
      document.body.appendChild(downloadLink)

      // Trigger a click event to prompt the user to save the file
      downloadLink.click()

      // Remove the anchor element from the document
      document.body.removeChild(downloadLink)
      commit('UPDATE_RESERVATIONS', response.data.data)
    }
  },
  mutations: {
    SET_RESERVATIONS (state, data) {
      state.reservations = data
    },
    SET_RESERVATION (state, data) {
      state.reservation = data.data
    },
    UPDATE_RESERVATIONS (state, data) {
      state.reservations.data = state.reservations.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_RESERVATIONS (state, id) {
      state.reservations.data = state.reservations.data.filter(x => x.id !== id)
    }

  }
}
