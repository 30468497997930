import $axios from '@/plugins/axios'
const model = 'mainSlider'
export default {
  state: {
    sliders: {
      data: []
    },
    slider: {
      sliders: []
    }
  },
  getters: {
    sliders: state => state.sliders,
    slider: state => state.slider
  },
  actions: {
    async getSliders ({ commit }, filterData = { page: 1 }) {
      const response = await $axios.get(`/${model}?page=${filterData.page}&take=10`)
      commit('SET_SLIDERS', response.data)
    },
    async showSlider ({ commit }, id) {
      const response = await $axios.get(`/${model}/${id}?include=sliders`)
      commit('SET_SLIDER', response.data.data)
    },
    async addSliders ({ commit }, payload) {
      const response = await $axios.post(`/${model}`, payload)
      commit('SORT_SLIDERS', response.data.data)
    },
    async addSliderBody ({ commit }, payload) {
      await $axios.post('/slider-body', payload)
      return commit
    },
    async updateSliders ({ commit }, payload) {
      const response = await $axios.put(`/${model}/${payload.id}`, payload)
      commit('UPDATE_SLIDERS', response.data.data)
    },
    async removeSliders ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_SLIDERS', response.data.data.id)
    }
  },
  mutations: {
    SET_SLIDERS (state, data) {
      state.sliders = data
    },
    SET_SLIDER (state, data) {
      state.slider = data
    },
    SORT_SLIDERS (state, data) {
      state.sliders.data.push(data)
    },
    UPDATE_SLIDERS (state, data) {
      state.sliders.data = state.sliders.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_SLIDERS (state, id) {
      state.sliders.data = state.sliders.data.filter(x => x.id !== id)
    }
  }
}
