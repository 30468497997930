import $axios from '@/plugins/axios'
const model = 'hotels-total-reservations'
export default {
  state: {
    accountants: [],
    hotelReservations: []
  },
  getters: {
    accountants: state => state.accountants,
    hotelReservations: state => state.hotelReservations
  },
  actions: {
    async getAccountants ({ commit }, filterData = {
      page: 1,
      title: ''
    }) {
      const response = await $axios.get(`/${model}?take=9&page=${filterData.page}&hotel_title=${filterData.title}`)
      commit('SET_ACCOUNTANT', response.data)
    },
    async getHotelReservationsById ({ commit }, filterData = { page: 1 }) {
      const response = await $axios.get(`/hotel-reservations/${filterData.id}?take=10&page=${filterData.page}&filter[full_name]=${filterData.full_name}&filter[reservation_no]=${filterData.reservation_no}&filter[phone]=${filterData.phone}`)
      commit('SET_RESERVATION', response.data)
    }
  },
  mutations: {
    SET_ACCOUNTANT (state, data) {
      state.accountants = data
    },
    SET_RESERVATION (state, data) {
      state.hotelReservations = data
    }

  }
}
