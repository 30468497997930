import $axios from '@/plugins/axios'
const model = 'currency'
export default {
  state: {
    currency: []
  },
  getters: {
    currency: state => state.currency
  },
  actions: {
    async getCurrency ({ commit }, filterData = { name: '' }) {
      const response = await $axios.get(`/${model}?filter[currency_name]=${filterData.name}`)
      commit('SET_CURRENCY', response.data)
    },
    async addCurrency ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
      // commit('SORT_GOVERNORATES', response.data.data)
    },
    async updateCurrency (_, payload) {
      await $axios.post(`/${model}/${payload.id}`, payload.data)
      // commit('UPDATE_GOVERNORATES', response.data.data)
    },
    async removeCurrency ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_CURRENCY', response.data.data.id)
    }
  },
  mutations: {
    SET_CURRENCY (state, data) {
      state.currency = data
    },
    SORT_CURRENCY (state, data) {
      state.currency.data.push(data)
    },
    UPDATE_CURRENCY (state, data) {
      state.currency.data = state.currency.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_CURRENCY (state, id) {
      state.currency.data = state.currency.data.filter(x => x.id !== id)
    }
  }
}
