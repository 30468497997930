import Vue from 'vue'
import VueRouter from 'vue-router'
/* Layouts */
// const Layout1 = () => import('../layouts/Layout1.vue')
const Layout2 = () => import('../layouts/Layout2.vue')
const AuthLayout = () => import('../layouts/AuthLayouts/AuthLayout')
/* Dashboards View */
const Dashboard1 = () => import('../views/Dashboards/Dashboard1.vue')

/* Authentic View */
const SignIn1 = () => import('../views/AuthPages/Default/SignIn1')
const Hotels = () => import('../views/Hotels/hotels.vue')
const Accountant = () => import('../views/Accountant/index.vue')
const HotelReservation = () => import('../views/Accountant/hotel-reservation.vue')
const Hotel = () => import('../views/Hotels/hotel.vue')
const Room = () => import('../views/Hotels/room-details.vue')
const AddHotel = () => import('../views/Hotels/addHotel.vue')
const Slider = () => import('../views/Slider/slider.vue')
const SliderData = () => import('../views/Slider/slider-data.vue')
const LandmarkSliderData = () => import('../views/Slider/landmark-slider-data.vue')
const Users = () => import('../views/User/users.vue')
const HotelUsers = () => import('../views/User/hotel-users.vue')
const Services = () => import('../views/Services/services.vue')
const Governorates = () => import('../views/Governorates/governorates.vue')
const Cities = () => import('../views/Cities/cities.vue')
const Features = () => import('../views/Features/features.vue')
const LandMark = () => import('../views/LandMarks/landMarks.vue')
const Question = () => import('../views/CommonQuestion/index.vue')
const Offers = () => import('../views/Offers/offers.vue')
const AddOffers = () => import('../views/Offers/addOffer.vue')
const Permissions = () => import('../views/Permissions/permissions.vue')
const RolesForm = () => import('../views/Roles/RolesForm.vue')
const Roles = () => import('../views/Roles/roles.vue')
const Contact = () => import('../views/Contact/index.vue')
const Clients = () => import('../views/Clintes/clintes.vue')
const ClientsHistory = () => import('../views/Clintes/clinet-transactions.vue')
const Currency = () => import('../views/Currency/currency.vue')
const Reservations = () => import('../views/Reservations/reservations-table.vue')

Vue.use(VueRouter)
const authChildRoutes = (prop) => [
  {
    path: '/login',
    name: prop + '.sign-in1',
    meta: { auth: true },
    component: SignIn1
  }
]
const routes = [
  {
    path: '/',
    component: Layout2,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'statistic',
        meta: { auth: true, name: 'Home 1' },
        component: Dashboard1
      },
      {
        path: '/slider',
        name: 'slider',
        meta: { auth: true, name: 'Home 1' },
        component: Slider
      },
      {
        path: '/slider-data/:id',
        name: 'slider-data',
        meta: { auth: true, name: 'SliderData' },
        component: SliderData
      },
      {
        path: '/land-slider-data/:id',
        name: 'land-slider-data',
        meta: { auth: true, name: 'LandSliderData' },
        component: LandmarkSliderData
      },
      {
        path: '/hotels',
        name: 'hotels',
        meta: { auth: true, name: 'Hotels' },
        component: Hotels
      },
      {
        path: '/accountant',
        name: 'accountant',
        meta: { auth: true, name: 'Accountant' },
        component: Accountant
      },
      {
        path: '/hotel-reservation/:id',
        name: 'hotel-reservation',
        meta: { auth: true, name: 'hotel-reservation' },
        component: HotelReservation
      },
      {
        path: '/hotel/:id',
        name: 'hotel',
        meta: { auth: true, name: 'Hotels' },
        component: Hotel
      },
      {
        path: '/room/:id',
        name: 'room',
        meta: { auth: true, name: 'Room' },
        component: Room
      },
      {
        path: '/add-hotel/:id?',
        name: 'add-hotel',
        meta: { auth: true, name: 'AddHotels' },
        component: AddHotel
      },
      {
        path: '/users',
        name: 'users',
        meta: { auth: true, name: 'users' },
        component: Users
      },
      {
        path: '/hotel-users',
        name: 'hotel-users',
        meta: { auth: true, name: 'hotel-users' },
        component: HotelUsers
      },
      {
        path: '/services',
        name: 'services',
        meta: { auth: true, name: 'Services' },
        component: Services
      },
      {
        path: '/governorates',
        name: 'governorates',
        meta: { auth: true, name: 'Governorates' },
        component: Governorates
      },
      {
        path: '/cities',
        name: 'cities',
        meta: { auth: true, name: 'Cities' },
        component: Cities
      },
      {
        path: '/features',
        name: 'features',
        meta: { auth: true, name: 'Features' },
        component: Features
      },
      {
        path: '/land-mark',
        name: 'landmark',
        meta: { auth: true, name: 'landmark' },
        component: LandMark
      },
      {
        path: '/questionk',
        name: 'commonQuestion',
        meta: { auth: true, name: 'commonQuestion' },
        component: Question
      },
      {
        path: '/offers',
        name: 'offers',
        meta: { auth: true, name: 'offers' },
        component: Offers
      },
      {
        path: '/add-offers/:id?',
        name: 'add-offers',
        meta: { auth: true, name: 'add-offers' },
        component: AddOffers
      },
      {
        path: '/permissions',
        name: 'permissions',
        meta: { auth: true, name: 'Permissions' },
        component: Permissions
      },
      {
        path: '/role-form/:id?',
        name: 'RolesForm',
        meta: { auth: true, name: 'RolesForm' },
        component: RolesForm
      },
      {
        path: '/roles',
        name: 'roles',
        meta: { auth: true, name: 'Roles' },
        component: Roles
      },
      {
        path: '/contact',
        name: 'contact',
        meta: { auth: true, name: 'Roles' },
        component: Contact
      },
      {
        path: '/clients',
        name: 'clients',
        meta: { auth: true, name: 'clients' },
        component: Clients
      },
      {
        path: '/clients-history/:id?',
        name: 'clients',
        meta: { auth: true, name: 'clients' },
        component: ClientsHistory
      },
      {
        path: '/currency',
        name: 'currency',
        meta: { auth: true, name: 'currency' },
        component: Currency
      },
      {
        path: '/reservations',
        name: 'reservations',
        meta: { auth: true, name: 'currency' },
        component: Reservations
      }
    ]
  },
  {
    path: '/auth',
    name: 'auth1',
    component: AuthLayout,
    meta: { auth: true },
    children: authChildRoutes('auth1')
  }
  // {
  //   path: '/app',
  //   name: 'app',
  //   component: Layout2,
  //   meta: { auth: true },
  //   children: mainRoute('app')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/auth/sign-in1', '/auth/sign-up1', '/dark/auth/sign-in1', '/dark/auth/sign-up1']
//   if (publicPages.includes(to.path)) {
//     localStorage.removeItem('user')
//     localStorage.removeItem('access_token')
//   }
//   const authRequired = !publicPages.includes(to.path)
//   const loggedIn = localStorage.getItem('user')
//   if (to.meta.auth) {
//     if (authRequired && loggedIn === null) {
//       return next()
//     } else if (to.name === 'dashboard' || to.name === 'dashboard.home-1') {
//       return next()
//     }
//   }
//   next()
// })

export default router
