import $axios from '@/plugins/axios'
import i18n from '@/i18n'
const model = 'offer'
export default {
  state: {
    offers: [],
    offer: {}
  },
  getters: {
    offers: state => state.offers,
    offer: state => state.offer
  },
  actions: {
    async getOffers ({ commit }, filterData = {
      page: 1,
      ar_title: '',
      en_title: '',
      kr_title: ''
    }) {
      const response = await $axios.get(`/${model}?include=governorate&take=10&page=${filterData.page}&filter[${i18n.locale}_title]=${filterData[`${i18n.locale}_title`]}`)
      commit('SET_OFFERS', response.data)
    },
    async getOffer ({ commit }, id) {
      const response = await $axios.get(`/${model}/${id}`)
      commit('SET_OFFER', response.data.data)
    },
    async addOffers (_, payload) {
      return await $axios.post(`/${model}`, payload)
    },
    async updateOffers (_, payload) {
      return await $axios.post(`/${model}/${payload.id}`, payload.payload)
    },
    async removeOffers ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_OFFER', response.data.data.id)
    }
  },
  mutations: {
    SET_OFFERS (state, data) {
      state.offers = data
    },
    SET_OFFER (state, data) {
      state.offer = data
    },
    REMOVE_OFFER (state, id) {
      state.offers.data = state.offers.data.filter(x => x.id !== id)
    }
  }
}
