import $axios from '@/plugins/axios'
import i18n from '@/i18n'
const model = 'city'
export default {
  state: {
    cities: []
  },
  getters: {
    cities: state => state.cities
  },
  actions: {
    async getCities ({ commit }, filterData = {
      take: 18,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: '',
      governorate_id: ''
    }) {
      const response = await $axios.get(`/${model}?include=governorate,createdBy,updatedBy&take=${filterData.take}&page=${filterData.page}&filter[${i18n.locale}_name]=${filterData[`${i18n.locale}_name`]}&filter[governorate_id]=${filterData.governorate_id}`)
      commit('SET_CITIES', response.data)
    },
    async addCities ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
      // commit('SORT_CITIES', response.data.data)
    },

    async updateCities ({ commit }, payload) {
      await $axios.put(`/${model}/${payload.id}`, payload)
      // commit('UPDATE_CITIES', response.data.data)
    },

    async removeCities ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_CITIES', response.data.data.id)
    }
  },
  mutations: {
    SET_CITIES (state, data) {
      state.cities = data
    },
    SORT_CITIES (state, data) {
      state.cities.data.push(data)
    },
    UPDATE_CITIES (state, data) {
      state.cities.data = state.cities.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_CITIES (state, id) {
      state.cities.data = state.cities.data.filter(x => x.id !== id)
    }

  }
}
