import $axios from '@/plugins/axios'
const model = 'service'
export default {
  state: {
    services: []
  },
  getters: {
    services: state => state.services
  },
  actions: {
    async getServices ({ commit }, filterData = { page: 0, name: '', service_type: 0 }) {
      const response = await $axios.get(`/${model}?include=createdBy,updatedBy&take=10&page=${filterData.page}&filter[en_name]=${filterData.name}&filter[service_type]=${filterData.service_type}`)
      commit('SET_SERVICES', response.data)
    },
    async addServices ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
      // commit('SORT_SERVICES', response.data.data)
    },

    async updateServices ({ commit }, payload) {
      await $axios.put(`/${model}/${payload.id}`, payload)
      // commit('UPDATE_SERVICES', response.data.data)
    },

    async removeServices ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_SERVICES', response.data.data.id)
    }
  },
  mutations: {
    SET_SERVICES (state, data) {
      state.services = data
    },
    SORT_SERVICES (state, data) {
      state.services.data.push(data)
    },
    UPDATE_SERVICES (state, data) {
      state.services.data = state.services.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_SERVICES (state, id) {
      state.services.data = state.services.data.filter(x => x.id !== id)
    }

  }
}
