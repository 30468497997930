import $axios from '@/plugins/axios'
const model = 'room'
export default {
  state: {
    rooms: [],
    room: {}
  },
  getters: {
    rooms: state => state.rooms,
    room: state => state.room
  },
  actions: {
    async getRooms ({ commit }, filterData = { take: 10, skip: 0, name: '' }) {
      const response = await $axios.get(`/${model}?include=createdBy,updatedBy`)
      commit('SET_ROOMS', response.data)
    },
    async getRoom ({ commit }, id) {
      const response = await $axios.get(`/${model}/${id}`)
      commit('SET_ROOM', response.data)
    },
    async addRooms ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
    },
    async updateRooms ({ commit }, payload) {
      const response = await $axios.put(`/${model}/${payload.id}`, payload)
      commit('UPDATE_ROOMS', response.data.data)
    },
    async removeRooms ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_ROOMS', response.data.data.id)
    }
  },
  mutations: {
    SET_ROOMS (state, data) {
      state.rooms = data
    },
    SET_ROOM (state, data) {
      state.room = data
    },
    SORT_ROOMS (state, data) {
      state.rooms.data.push(data)
    },
    UPDATE_ROOMS (state, data) {
      state.rooms.data = state.rooms.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_ROOMS (state, id) {
      state.rooms.data = state.rooms.data.filter(x => x.id !== id)
    }
  }
}
