import $axios from '@/plugins/axios'
import i18n from '@/i18n'
const model = 'governorate'
export default {
  state: {
    governorates: []
  },
  getters: {
    governorates: state => state.governorates
  },
  actions: {
    async getGovernorates ({ commit }, filterData = {
      take: 10,
      page: 1,
      ar_name: '',
      en_name: '',
      kr_name: ''
    }) {
      const response = await $axios.get(`/${model}?include=createdBy,updatedBy&take=${filterData.take}&page=${filterData.page}&filter[${i18n.locale}_name]=${filterData[`${i18n.locale}_name`]}`)
      commit('SET_GOVERNORATES', response.data)
    },
    async addGovernorates ({ commit }, payload) {
      await $axios.post(`/${model}`, payload)
      // commit('SORT_GOVERNORATES', response.data.data)
    },
    async updateGovernorates ({ commit }, payload) {
      await $axios.put(`/${model}/${payload.id}`, payload)
      // commit('UPDATE_GOVERNORATES', response.data.data)
    },
    async removeGovernorates ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_GOVERNORATES', response.data.data.id)
    }
  },
  mutations: {
    SET_GOVERNORATES (state, data) {
      state.governorates = data
    },
    SORT_GOVERNORATES (state, data) {
      state.governorates.data.push(data)
    },
    UPDATE_GOVERNORATES (state, data) {
      state.governorates.data = state.governorates.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_GOVERNORATES (state, id) {
      state.governorates.data = state.governorates.data.filter(x => x.id !== id)
    }
  }
}
