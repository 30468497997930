import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import ar from './locales/ar.json'
import kr from './locales/kr.json'
Vue.use(VueI18n)

// function loadLocaleMessages () {
//   const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
//   const messages = {}
//   locales.keys().forEach(key => {
//     const matched = key.match(/([A-Za-z0-9-_]+)\./i)
//     if (matched && matched.length > 1) {
//       const locale = matched[1]
//       messages[locale] = locales(key)
//     }
//   })
//   return messages
// }

export default new VueI18n({
  locale: localStorage.getItem('booking-lang') || 'en',
  fallbackLocale: localStorage.getItem('booking-lang') || 'en',
  messages: {
    ar,
    en,
    kr
  }
})
