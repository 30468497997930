import $axios from '@/plugins/axios'
const model = 'hotel'
export default {
  state: {
    hotels: {
      data: []
    },
    hotel: {
      id: '',
      features: []
    }
  },
  getters: {
    hotels: state => state.hotels,
    hotel: state => state.hotel
  },
  actions: {
    async getHotels ({ commit }, filterData = { page: 1, title: '', hotel_code: '' }) {
      const response = await $axios.get(`/${model}?include=governorate,city,createdBy,updatedBy&take=10&page=${filterData.page}&filter[title]=${filterData.title}&filter[hotel_code]=${filterData.hotel_code}`)
      commit('SET_HOTELS', response.data)
    },
    async getHotel ({ commit }, id) {
      const response = await $axios.get(`/${model}/${id}?include=features,services,rooms,images`)
      commit('SET_HOTEL', response.data.data)
    },
    async addHotels ({ commit }, payload) {
      const response = await $axios.post(`/${model}`, payload)
      commit('SORT_HOTELS', response.data.data)
    },
    async updateHotels ({ commit }, payload) {
      const response = await $axios.post(`/${model}/${payload.id}`, payload.payload)
      commit('UPDATE_HOTELS', response.data.data)
    },
    async removeHotels ({ commit }, id) {
      const response = await $axios.delete(`/${model}/${id}`)
      commit('REMOVE_HOTELS', response.data.data.id)
    },
    async assignPermissionsToUsers ({ commit }, payload) {
      await $axios.post(`/${model}/${payload.userId}`, payload.permissions)

      // commit('REMOVE_GOVERNORATES', response.data.data.id)
    },
    async activeHotel ({ commit }, id) {
      await $axios.put(`active/hotel/${id}`)
    },
    async showingHotel ({ commit }, id) {
      await $axios.put(`show-in-website/hotel/${id}`)
    }

  },
  mutations: {
    SET_HOTELS (state, data) {
      state.hotels = data
    },
    SET_HOTEL (state, data) {
      state.hotel = data
    },
    SORT_HOTELS (state, data) {
      state.hotels.data.push(data)
    },
    UPDATE_HOTELS (state, data) {
      state.hotels.data = state.hotels.data.map(item => item.id === data.id ? data : item)
    },
    REMOVE_HOTELS (state, id) {
      state.hotels.data = state.hotels.data.filter(x => x.id !== id)
    }
  }
}
